// import { usePrompt } from '../hooks/usePrompt'
import { HStack } from '@chakra-ui/react'

import PrivateRouteLayout from '../../layouts/PrivateRouteLayout'

import DeliveryForm from './DeliveryForm'

export default function NewOrder() {
  // usePrompt('Changes you made will not be saved.', true)

  return (
    <PrivateRouteLayout>
      <HStack align="start" mx="20" mt="20" justifyContent="space-around">
        <DeliveryForm />
      </HStack>
    </PrivateRouteLayout>
  )
}
