import { ReactNode, useState } from 'react'

import * as styles from './tableRow.module.css'
import classNames from 'classnames'
import {
  BasicTableDataRow,
  TableCellContentAlignEnum,
  TableDataRow,
  TableHeaderItem,
} from './HeaderItem'
import { useColorModeValue } from '@chakra-ui/react'

export interface TableRowProps<T extends BasicTableDataRow> {
  row: TableDataRow<T>
  headerList: TableHeaderItem[]
}

export function TableRow<T extends BasicTableDataRow>({ headerList, row }: TableRowProps<T>) {
  const textColor = useColorModeValue('inherit', '#fff')
  const cellBorderColor = useColorModeValue('inherit', '#df1c8e')
  const [isHover, setIsHover] = useState(false)
  const handleMouseEnter = () => {
    setIsHover(true)
  }
  const handleMouseLeave = () => {
    setIsHover(false)
  }
  const rowStyle = {
    borderBottomColor: cellBorderColor,
  }

  const columnStyle = {
    color: textColor,
  }
  const rowActionsStyle = {
    color: textColor,
  }

  return (
    <tr
      className={styles.default.tableRow}
      style={rowStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {headerList.map((li: any, index: number) => {
        return (
          <td
            key={index}
            style={Object.assign(
              {
                textAlign: li.alignment,
              },
              columnStyle,
            )}
            className={classNames({
              textLeft: !li.alignment || li.alignment === TableCellContentAlignEnum.LEFT,
              textCenter: li.alignment === TableCellContentAlignEnum.CENTER,
              textRight: li.alignment === TableCellContentAlignEnum.RIGHT,
            })}
          >
            {row[li.id] as ReactNode}
          </td>
        )
      })}
    </tr>
  )
}

export default TableRow
