import { Api } from '.'
import { LineItem } from '../models/lineItem'

export default class OrderLineItemApi {
  static async get(id: string) {
    return Api.get<LineItem>(`/order-line-item/${id}`)
  }

  static async create(lineItem: LineItem) {
    return Api.post<LineItem>(`/order-line-item/`, JSON.stringify(lineItem), {
      headers: { 'Content-Type': 'application/json' },
    })
  }

  static async update(lineItem: LineItem) {
    return Api.patch<LineItem>(`/order-line-item/${lineItem.id}`, JSON.stringify(lineItem), {
      headers: { 'Content-Type': 'application/json' },
    })
  }

  static async remove(lineItem: LineItem) {
    return Api.delete<LineItem>(`/order-line-item/${lineItem.id}`)
  }

  static async list() {
    return Api.get<LineItem[]>('/order-line-item/')
  }

  static async getByOrder(order_id: string) {
    return Api.get<LineItem[]>(`/order-line-item/findbyorder/${order_id}`)
  }
}
