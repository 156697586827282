import React from 'react'
import { HStack, Flex, Spinner, Text, useColorModeValue, Fade } from '@chakra-ui/react'

export default function LoadingScreen() {
  const [dots, setDots] = React.useState('')

  React.useEffect(() => {
    const i = setInterval(() => {
      setDots('.'.repeat((dots.length + 1) % 4))
    }, 1000)

    return () => {
      clearInterval(i)
    }
  }, [dots])

  return (
    <Flex
      minH="100vh"
      align="center"
      justify="center"
      bg={useColorModeValue('gray.50', 'gray.800')}
      position="relative"
    >
      <Fade in={true} transition={{ enter: { duration: 2 } }}>
        <HStack>
          <Spinner speed="0.8s" />
          <Text fontSize={{ base: 'xl', md: '2xl' }}>Loading{dots}</Text>
        </HStack>
      </Fade>
    </Flex>
  )
}
