import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  chakra,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  HStack,
  Select,
  Stack,
  Textarea,
  Text,
  CircularProgress,
  useToast,
} from '@chakra-ui/react'
import { MdArrowDropDown } from 'react-icons/md'
import { useFormik } from 'formik'
import * as yup from 'yup'

import states from '../../constants/states.json'
import { FreightType, Order, OrderCompanyRelation, OrderStatus } from '../../models/order'
import OrderApi from '../../api/order'
import { useAuth } from '../../context/AuthContext'
import CompanyApi from '../../api/company'
import { useNavigate } from 'react-router-dom'
import { Company } from '../../models/company'

export default function DeliveryForm() {
  const { authUser } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [_wholesalers, setWholesalers] = useState<Company[]>([])
  const [_retailers, setRetailers] = useState<Company[]>([])
  const toast = useToast()

  const navigate = useNavigate()

  //TODO: Make only admin
  useEffect(() => {
    async function init() {
      const res = await CompanyApi.getWholesalers()
      setWholesalers(res.data)
    }
    init()
  }, [])

  // async function UpdateRetailers(id: string) {
  //   const res = await CompanyApi.getRetailersByWholesaler(id)
  //   setRetailers(res.data)
  // }

  type FormData = {
    po_number: string
    // line_1: string
    // line_2: string
    // city: string
    // state: string
    // zip?: number
    instructions?: string
    freightType: string
    wholesaler?: string
    retailer?: string
  }

  async function fetchRetailers(wholesalerId: string | null = null) {
    // const wholesalerId = formik.values.wholesaler?.company?.id || null;
    if (wholesalerId) {
      const res = await CompanyApi.getRetailersByWholesaler(wholesalerId)
      setRetailers(res.data)
    }
  }

  function sendOrder(): void {
    let order = new Order()
    order.loadDeliveryFormData(
      formik.values.po_number,
      OrderStatus.RECEIVED,
      formik.values.freightType,
      formik.values.instructions || '',
      authUser!.uid,
    )
    order.wholesalerCustomer = formik.values.wholesaler
    order.retailerCustomer = formik.values.retailer
    OrderApi.create(order).then(res => {
      if (res.status === 201) {
        formik.resetForm()
        // formik.setFieldValue('state', undefined)
        formik.setFieldValue('zip', '')
        toastNotification(
          'success',
          'Order created.',
          'Your Order was successfully Created. You will be redirected to your order form shortly.',
          () => {
            console.log('redirecting to order #: ', res.data.id)
            navigate(`/order?orderUid=${res.data.id}`)
          },
          2000,
        )
      }
    })
  }

  function toastNotification(
    status: 'success' | 'info' | 'warning' | 'error',
    title: string,
    message: string,
    redirect?: () => void,
    duration = 5000,
  ): void {
    toast({
      title: title,
      description: message,
      status: status,
      duration: duration,
      isClosable: true,
      onCloseComplete: () => redirect && redirect(),
    })
  }

  const formik = useFormik<FormData>({
    // Hook up db values when onboarding section is complete
    initialValues: {
      po_number: '',
      instructions: '',
      freightType: '',
      wholesaler: undefined,
      retailer: undefined,
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: yup.object().shape({
      po_number: yup.string().label('PO Number').required(),
      instructions: yup.string().label('Instructions').optional(),
      freightType: yup.string().label('Freight Type').required(),
      wholesaler: yup.string().label('Wholesaler').required(),
      retailer: yup.string().label('Retailer').optional(),
    }),
    onSubmit: async () => {
      //TODO Line item
      try {
        setIsLoading(true)
        sendOrder() //This may change with the new multipage
        //Go to lineItems.
      } catch {
        toastNotification('error', 'Error', 'Something went wrong.')
      } finally {
        setIsLoading(false)
      }
      //Check if create to reset the form
    },
  })

  return (
    <chakra.form
      onSubmit={e => {
        e.preventDefault()
        formik.handleSubmit()
      }}
    >
      <Stack direction={['column', 'row']} spacing="5%">
        <Stack>
          <Stack>
            <Text as="h2" fontSize="3xl" fontWeight="semibold" pb="4">
              USER DATA
            </Text>
          </Stack>
          {/* <Stack spacing={4}>
            <FormControl isInvalid={!!formik.errors.wholesalerUid}>
              <FormLabel>Wholesaler</FormLabel>
              <Select
                id="wholesaler"
                placeholder="Select Wholesaler"
                icon={<MdArrowDropDown />}
                // value={formik.values.wholesalerUid}
                onChange={e => {
                  formik.setFieldValue('wholesalerUid', e.target.value)
                  // if (e.target.value != null && e.target.value.length > 0)
                  // UpdateRetailers(e.target.value)
                }}
              >
                {wholesalers?.map(({ name, id }) => (
                  <option key={name} value={id}>
                    {name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{formik.errors.wholesalerUid}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!formik.errors.retailerUid}>
              <FormLabel>Retailer</FormLabel>
              <Select
                id="retailer"
                icon={<MdArrowDropDown />}
                value={formik.values.retailerUid}
                onChange={formik.handleChange}
              >
                {retailers.map(({ name, id }) => (
                  <option key={name} value={id}>
                    {name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{formik.errors.retailerUid}</FormErrorMessage>
            </FormControl>
          </Stack> */}
        </Stack>
        <Stack>
          <Stack>
            <Text as="h2" fontSize="3xl" fontWeight="semibold" pb="4">
              DELIVERY
            </Text>
          </Stack>
          <Stack spacing={4}>
            <FormControl isRequired isInvalid={!!formik.errors.po_number}>
              <FormLabel>Purchase Order Number</FormLabel>
              <Input
                id="po_number"
                type="po_number"
                value={formik.values.po_number}
                onChange={formik.handleChange}
              />
              <FormErrorMessage>{formik.errors.po_number}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Wholesaler</FormLabel>
              <Select
                id="wholesaler"
                placeholder="Select Wholesaler"
                icon={<MdArrowDropDown />}
                value={formik.values.wholesaler}
                onChange={event => {
                  formik.handleChange(event)
                  fetchRetailers(event.target.value)
                }}
              >
                {_wholesalers &&
                  _wholesalers.map(({ name, id }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
              </Select>
            </FormControl>
            {_retailers.length > 0 && (
              <FormControl>
                <FormLabel>Retailer</FormLabel>
                <Select
                  id="retailer"
                  placeholder="Select Retailer"
                  icon={<MdArrowDropDown />}
                  value={formik.values.retailer}
                  onChange={formik.handleChange}
                >
                  {_retailers.map(({ name, id }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}
            <FormControl isInvalid={!!formik.errors.freightType}>
              <FormLabel>Freight Type</FormLabel>
              <Select
                id="freightType"
                placeholder="Select Freight Type"
                icon={<MdArrowDropDown />}
                value={formik.values.freightType}
                onChange={formik.handleChange}
              >
                <option key={FreightType.TRIAXLE} value={FreightType.TRIAXLE}>
                  {FreightType.TRIAXLE}
                </option>
                <option key={FreightType.RAILCAR} value={FreightType.RAILCAR}>
                  {FreightType.RAILCAR}
                </option>
              </Select>
              <FormErrorMessage>{formik.errors.freightType}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!formik.errors.instructions}>
              <FormLabel>Instructions</FormLabel>
              <Textarea
                id="instructions"
                value={formik.values.instructions}
                onChange={formik.handleChange}
              />
              <FormErrorMessage>{formik.errors.instructions}</FormErrorMessage>
            </FormControl>
          </Stack>
        </Stack>
      </Stack>
      <Flex justify="end" mt="2">
        <Button type="submit" mt="4">
          {isLoading ? <CircularProgress isIndeterminate size="24px" color="teal" /> : 'Next'}
        </Button>
      </Flex>
    </chakra.form>
  )
}
