import React from 'react'

import UserApi, { User } from '../api/user'

import { useAuth } from './AuthContext'

type UserContextType = {
  user: User | null
  api: typeof UserApi
}

const UserContext = React.createContext<UserContextType>({
  user: null,
  api: UserApi,
} as any)

export const useUser = () => React.useContext(UserContext)

export default function UserProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = React.useState<User | null>(null)

  const { authUser } = useAuth()

  React.useEffect(() => {
    if (authUser?.uid) {
      // API to fetch user, delayed til next tick to allow for localStorage to be set upon login

      setTimeout(() => {
        UserApi.get(authUser.uid).then(res => {
          if (res.data && res.data.uid) {
            setUser(res.data)
          }
        })
      }, 0)
    } else {
      setUser(null)
    }
  }, [authUser?.uid])

  return <UserContext.Provider value={{ user, api: UserApi }}>{children}</UserContext.Provider>
}
