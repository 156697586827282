/* eslint-disable react/jsx-key */
import { Button, Input, Table, Thead, Tbody, Tr, Th, Td, Box } from '@chakra-ui/react'
import { useTable, TableOptions } from 'react-table'
import { FiTrash2 } from 'react-icons/fi'
import { LineItem } from '../../../../models/lineItem'
import { ChangeEvent, Fragment } from 'react'
import { convertStringTo2Decimals } from '../../../../utils'

type Props<D extends object> = Pick<TableOptions<D>, 'columns' | 'data'> & {
  removeRow: (id: number, removedRow: LineItem) => void
  updateRow: (index: number, row: any) => void
  taxes: string
  freight: string
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

export default function OrderTable<D extends object>(props: Props<D>) {
  const { columns, data, removeRow, updateRow, taxes, freight, handleChange } = props

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    initialState: { hiddenColumns: ['series_id', 'series.piecesPerUnit'] },
  })

  // function updatePieces(rowIndex: number, row: Row) {}
  // function updateLifts(rowIndex: number, row: any) {
  //   updateRow(rowIndex, {
  //     ...row.original,
  //     //This will need to be able to use the number of pieces of a series times the lift.
  //   })
  // }

  console.log('taxes: ', taxes)
  console.log('freight: ', freight)

  const totalPriceUnrounded =
    data.reduce((acc: number, curr: any) => {
      const lineItem = curr as LineItem
      const pieces = lineItem.pieces ?? 0
      const length = parseInt(lineItem.series!.length)
      let linePrice: number =
        typeof lineItem.price === 'number' ? lineItem.price : parseFloat(curr.price)
      let totalLinearFeet = pieces * length
      return acc + linePrice * totalLinearFeet
    }, 0) +
    parseFloat(taxes) +
    parseFloat(freight)

  console.log('totalPriceUnrounded', totalPriceUnrounded)

  const totalPriceMultipliedBy100 = Math.round(totalPriceUnrounded * 100)

  console.log('totalPriceMultipliedBy100', totalPriceMultipliedBy100)

  const totalPrice = totalPriceMultipliedBy100 / 100

  console.log('totalPrice', totalPrice)

  return (
    <Table {...getTableProps()}>
      <Thead>
        {headerGroups.map(headerGroup => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) =>
              column.hideHeader === true ? null : (
                <Th {...column.getHeaderProps()}>{column.render('Header')}</Th>
              ),
            )}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row, rowIndex) => {
          prepareRow(row)
          return (
            <Tr {...row.getRowProps()}>
              {row.cells.map((cell, i, arr) => {
                if (i === arr.length - 1) {
                  // Last column is for the remove icon
                  return (
                    <Td {...cell.getCellProps()}>
                      <Button
                        colorScheme="red"
                        onClick={() => removeRow(rowIndex, row.original as LineItem)}
                      >
                        <FiTrash2 />
                      </Button>
                    </Td>
                  )
                  // } else if (i === 0) {
                  //   // ID
                  //   return <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                  // } else if (i === 1) {

                  //   <Td {...cell.getCellProps()}>
                  //      <Input
                  //         value={cell.value.series}
                  //         type="string"
                  //         onChange={e => {}
                  //         //   updateRow(rowIndex, {
                  //         //     ...row.original,
                  //         //     lifts: parseInt(e.target.value) || 0,
                  //         //     pieces: parseInt(e.target.value) * row.values.piecesPerLift,
                  //         //   })
                  //         }
                  //       />
                  //   </Td>
                } else if (cell.column.id === 'series.weight') {
                  let lineItem: LineItem = row.original as LineItem
                  const pieces = lineItem?.pieces || 0
                  const weight = lineItem?.series?.weight || 0
                  // const length = parseFloat(lineItem?.series?.length || '0')
                  // const totalFeet = pieces * length
                  const totalWeight = pieces * weight
                  return <Td {...cell.getCellProps()}>{totalWeight} lbs.</Td>
                } else if (cell.column.id === 'remaining_pieces') {
                  let lineItem: LineItem = row.original as LineItem
                  const piecesPerUnit = lineItem.series?.piecesPerUnit || 1
                  return (
                    <td {...cell.getCellProps()}>
                      {lineItem.pieces ? lineItem.pieces! % piecesPerUnit : 0}
                    </td>
                  )
                } else if (cell.column.id === `price`) {
                  let savedValue = cell.value
                  return (
                    <Td {...cell.getCellProps()}>
                      <Box display="flex" alignItems="center">
                        $
                        <Input
                          type="number"
                          value={cell.value}
                          onClick={(e: any) => {
                            savedValue = e.target.value
                            e.target.value = ''
                          }}
                          onBlur={e => {
                            if (e.target.value === '') {
                              e.target.value = savedValue
                            }
                          }}
                          onChange={e => {
                            let previousData: LineItem = row.original as LineItem
                            if (e.target.value) {
                              previousData.price = convertStringTo2Decimals(e.target.value)
                            } else {
                              previousData.price = 0
                            }
                            updateRow(rowIndex, previousData)
                          }}
                        />
                      </Box>
                    </Td>
                  )
                } else {
                  let disabled = cell.column.id !== 'quantity' && cell.column.id !== 'pieces'
                  return (
                    <Td {...cell.getCellProps()}>
                      <Input
                        disabled={disabled}
                        value={cell.value}
                        onChange={e => {
                          let previousData: LineItem = row.original as LineItem
                          if (e.target.value) {
                            const piecesPerUnit = previousData.series?.piecesPerUnit || 1
                            if (cell.column.id === 'pieces') {
                              previousData.pieces = parseInt(e.target.value)
                              previousData.lifts = Math.floor(previousData.pieces / piecesPerUnit)
                              previousData.quantity = previousData.lifts
                            } else if (cell.column.id === 'quantity') {
                              previousData.quantity = parseInt(e.target.value)
                              previousData.lifts = previousData.quantity
                              previousData.pieces = piecesPerUnit * previousData.quantity
                            }
                          } else {
                            previousData.pieces = 0
                            previousData.lifts = 0
                            previousData.quantity = 0
                          }
                          updateRow(rowIndex, previousData)
                        }}
                        // type="number"
                        // onChange={e =>
                        //   updateRow(rowIndex, {
                        //     ...row.original,
                        //     lifts: parseInt(e.target.value) || 0,
                        //     pieces: parseInt(e.target.value) * row.values.piecesPerLift,
                        //   })
                        // }
                      />
                    </Td>
                  )
                }
              })}
            </Tr>
          )
        })}
        {headerGroups.map(headerGroup => (
          <Fragment key={headerGroup.id}>
            {/* <Tr>
              {headerGroup.headers.map((column: any, index) => (
                <Td key={column.id}>
                  <div style={{ paddingLeft: '1rem' }}>
                    {column.showTotal ? (
                      <Box display="flex" alignItems="center">
                        $
                        {handleChange ? (
                          <Input
                            id="taxes"
                            name="taxes"
                            value={taxes === '0' ? '0' : taxes}
                            onChange={handleChange}
                          />
                        ) : (
                          <div>{taxes}</div>
                        )}
                      </Box>
                    ) : null}
                    {column.showLabel ? (
                      <Box display="flex" alignItems="center">
                        Taxes:
                      </Box>
                    ) : null}
                  </div>
                </Td>
              ))}
            </Tr> */}
            <Tr>
              {headerGroup.headers.map((column: any, index) => (
                <Td key={column.id}>
                  <div style={{ paddingLeft: '1rem' }}>
                    {column.showTotal ? (
                      <Box display="flex" alignItems="center">
                        $
                        {handleChange ? (
                          <Input
                            id="freight"
                            name="freight"
                            value={parseFloat(freight) === 0 ? 0 : parseFloat(freight)}
                            onChange={handleChange}
                          />
                        ) : (
                          <div>{freight}</div>
                        )}
                      </Box>
                    ) : null}
                    {column.showLabel ? (
                      <Box display="flex" alignItems="center">
                        Freight:
                      </Box>
                    ) : null}
                  </div>
                </Td>
              ))}
            </Tr>
            <Tr>
              {headerGroup.headers.map((column: any, index) => (
                <Td key={column.id}>
                  <div style={{ paddingLeft: '1rem' }}>
                    {column.showTotal ? (
                      <Box display="flex" alignItems="center">
                        ${totalPrice}
                      </Box>
                    ) : null}
                    {column.showLabel ? (
                      <Box display="flex" alignItems="center">
                        Total:
                      </Box>
                    ) : null}
                  </div>
                </Td>
              ))}
            </Tr>
          </Fragment>
        ))}
      </Tbody>
    </Table>
  )
}
