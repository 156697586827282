import React from 'react'
import {
  Button,
  FormControl,
  Heading,
  Input,
  Stack,
  Text,
  useColorModeValue,
  Link,
  FormErrorMessage,
  useDisclosure,
  Fade,
} from '@chakra-ui/react'
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'

import Routes from '../constants/routes'
import { useAuth } from '../context/AuthContext'
import PublicRouteLayout from '../layouts/PublicRouteLayout'

export default function ForgotPassword(): JSX.Element {
  const [didSubmit, setDidSubmit] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [countDown, setCountDown] = React.useState(5)

  const formDisclosure = useDisclosure({ defaultIsOpen: true })
  const confirmationDisclosure = useDisclosure({ defaultIsOpen: false })

  const navigate = useNavigate()
  const { resetPassword } = useAuth()

  const formColor = useColorModeValue('white', 'gray.700')
  const textColor = useColorModeValue('gray.800', 'gray.400')

  React.useEffect(() => {
    if (didSubmit) {
      const i = setInterval(() => {
        if (countDown > 0) {
          setCountDown(countDown - 1)
        } else if (countDown === 0) {
          clearInterval(i)
          navigate(Routes.login)
        }
      }, 1000)

      return () => {
        clearInterval(i)
      }
    }
  }, [didSubmit, countDown, navigate])

  const formik = useFormik<{ email: string }>({
    initialValues: { email: '' },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: yup.object().shape({
      email: yup.string().email().label('Email').required(),
    }),
    onSubmit: async ({ email }) => {
      try {
        setIsLoading(true)
        await resetPassword(email)
      } catch (error) {
        console.log((error as any).message)
        console.log((error as any).code)
      }

      setIsLoading(false)

      formDisclosure.onClose()

      setTimeout(() => {
        confirmationDisclosure.onOpen()
        setDidSubmit(true)
      }, 300)
    },
  })

  return (
    <PublicRouteLayout>
      {didSubmit ? (
        <Fade in={confirmationDisclosure.isOpen}>
          <Stack
            spacing={4}
            w="full"
            maxW="md"
            bg={formColor}
            rounded="xl"
            boxShadow="lg"
            p={6}
            my={7}
          >
            <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
              Success!
            </Heading>
            <Text fontSize={{ base: 'sm', sm: 'md' }} color={textColor}>
              You should receive an email shortly
            </Text>
            <Text align={'center'} alignSelf="flex-end">
              Return to{' '}
              <Link as={ReactRouterLink} to={Routes.login} color="blue.400" pr="3">
                Login
              </Link>
            </Text>
          </Stack>
          <Text fontSize={{ base: 'sm', sm: 'md' }} color={textColor} alignSelf="flex-end" pl="4">
            Redirecting in {countDown}
          </Text>
        </Fade>
      ) : (
        <Fade in={formDisclosure.isOpen} transition={{ exit: { duration: 0.3 } }}>
          <Stack
            as="form"
            spacing={4}
            w="full"
            maxW="md"
            bg={formColor}
            rounded="xl"
            boxShadow="lg"
            p={6}
            my={12}
            onSubmit={e => {
              e.preventDefault()
              formik.handleSubmit()
            }}
          >
            <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
              Forgot your password?
            </Heading>
            <Text fontSize={{ base: 'sm', sm: 'md' }} color={textColor}>
              You&apos;ll get an email with a reset link
            </Text>
            <FormControl isInvalid={!!formik.errors.email}>
              <Input
                id="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                type="email"
                placeholder="your-email@example.com"
                _placeholder={{ color: 'gray.500' }}
              />
              <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
            </FormControl>
            <Stack spacing={6}>
              <Button
                isLoading={isLoading}
                loadingText="Loading..."
                bg="blue.400"
                color="white"
                _hover={{
                  bg: 'blue.500',
                }}
                type="submit"
              >
                Request Reset
              </Button>
            </Stack>
            <Text align={'center'} alignSelf="flex-end">
              Back to{' '}
              <Link as={ReactRouterLink} to={Routes.login} color="blue.400" pr="3">
                Login
              </Link>
            </Text>
          </Stack>
        </Fade>
      )}
    </PublicRouteLayout>
  )
}
