import { DeleteIcon, DownloadIcon } from '@chakra-ui/icons'
import { IconButton, useToast } from '@chakra-ui/react'
import OrderApi from '../../api/order'
import { Attachment } from '../../models/order'

type Props = {
  attachment: Attachment
  onDelete?: (attachment: Attachment) => void
}

export default function AttachmentWidget({ attachment, onDelete }: Props) {
  const toast = useToast()

  function toastNotification(
    status: 'success' | 'info' | 'warning' | 'error',
    title: string,
    message: string,
    redirect?: () => void,
  ): void {
    toast({
      title: title,
      description: message,
      status: status,
      duration: 5000,
      isClosable: true,
      onCloseComplete: () => redirect && redirect(),
    })
  }

  function deleteAttachment(attachment: any): void {
    OrderApi.deleteAttachment(attachment.id)
      .then(res => {
        if (res.status === 200) {
          toastNotification(
            'success',
            'Attachment Deleted',
            `Deleting the attachment ${attachment.file_name} succeeded`,
          )
          if (onDelete != null) onDelete(attachment)
        } else {
          toastNotification(
            'error',
            'Attachment Deletion Failed',
            `Deleting the attachment ${attachment.file_name} failed`,
          )
        }
      })
      .catch(() => {
        toastNotification(
          'error',
          'Attachment Deletion Failed',
          `Deleting the attachment ${attachment.file_name} failed`,
        )
      })
  }

  async function downloadAttachment(attachment: Attachment): Promise<void> {
    let url = null
    if (attachment.attachment) {
      url = window.URL.createObjectURL(
        new Blob([new Uint8Array(attachment.attachment.data)], {
          type: 'application/octet-stream',
        }),
      )
    } else if (attachment && attachment.id) {
      let fetchedData = await OrderApi.getAttachment(attachment.id)
      url = window.URL.createObjectURL(
        new Blob([new Uint8Array(fetchedData.data.attachment.data)], {
          type: 'application/octet-stream',
        }),
      )
    } else {
      throw Error('Something went wrong with attachment download')
    }
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', attachment?.file_name || '')

    document.body.appendChild(link)

    link.click()

    link.parentNode?.removeChild(link)
  }

  return (
    <span>
      <span>{attachment.file_name}</span>
      <IconButton
        aria-label="Download Attachment"
        icon={<DownloadIcon />}
        onClick={() => downloadAttachment(attachment)}
      />
      <IconButton
        colorScheme="red"
        aria-label="Delete Attachment"
        icon={<DeleteIcon />}
        onClick={() => deleteAttachment(attachment)}
      />
    </span>
  )
}
