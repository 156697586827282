import { ReactNode } from 'react'

import TableRow from '../table-row/table-row'
import classNames from 'classnames'
import {
  BasicTableDataRow,
  TableCellContentAlignEnum,
  TableDataRow,
  TableHeaderItem,
} from '../table-row/HeaderItem'
import * as styles from './table.module.css'
import { useColorModeValue } from '@chakra-ui/react'

export interface TableProps<T extends BasicTableDataRow> {
  className?: string
  mapData?: (row: TableDataRow<T>, index: number) => TableDataRow<T> & { [key: string]: ReactNode }
  headerList: TableHeaderItem[]
  dataList: TableDataRow<T>[]
}

export function Table<T extends BasicTableDataRow>({
  className,
  dataList,
  mapData,
  headerList,
}: TableProps<T>) {
  const headerBackgroundColor = useColorModeValue('inherit', '#222')
  const tableBackgroundColor = useColorModeValue('inherit', '#333')
  const tableBorderColor = useColorModeValue('inherit', '#df1c8e')
  const textColor = useColorModeValue('inherit', '#fff')
  const cellBorderColor = useColorModeValue('inherit', '#df1c8e')
  const tableHeaderStyle = {
    borderColor: tableBorderColor,
    backgroundColor: tableBackgroundColor,
    borderRadius: '0.5rem',
    boxShadow: '0 0 #0000, 0 0 0 0, 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
    border: 'solid 1px #e0e0e0',
    overflow: 'hidden',
    marginTop: '2rem',
    width: '100%',
  }
  // const tableStyle =

  return (
    <div style={tableHeaderStyle}>
      <table
        style={{
          borderColor: tableBorderColor,
          backgroundColor: tableBackgroundColor,
          minWidth: '100%',
          borderCollapse: 'collapse',
          textIndent: '0',
        }}
      >
        <thead>
          <tr
            style={{
              backgroundColor: headerBackgroundColor,
              borderBottomColor: cellBorderColor,
            }}
          >
            {headerList.map(headerItem => (
              <th
                key={headerItem.id}
                scope="col"
                style={{
                  ...headerItem.headerStyles,
                  padding: '1rem 0.75rem 1rem 1.5rem',
                  color: 'rgb(17 24 39 / 1)',
                  fontWeight: 600,
                  fontSize: '0.875rem',
                  lineHeight: '1.25rem',
                }}
                className={classNames({
                  textLeft:
                    !headerItem.alignment ||
                    headerItem.alignment === TableCellContentAlignEnum.LEFT,
                  textCenter: headerItem.alignment === TableCellContentAlignEnum.CENTER,
                  textRight: headerItem.alignment === TableCellContentAlignEnum.RIGHT,
                })}
              >
                {headerItem.id != 'checkbox' ? headerItem.headerLabel : ''}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {dataList.map((row, index) => {
            let modifiedRow
            if (mapData) {
              modifiedRow = mapData(row, index)
            }
            return <TableRow key={index} headerList={headerList} row={modifiedRow || row} />
          })}
        </tbody>
      </table>
    </div>
  )
}

export default Table
