import { HStack } from '@chakra-ui/react'

import PrivateRouteLayout from '../../layouts/PrivateRouteLayout'

export default function Batching() {
  return (
    <PrivateRouteLayout>
      <HStack align="start" mx="20" mt="20" justifyContent="space-around">
        
      </HStack>
    </PrivateRouteLayout>
  )
}
