import React from 'react'

import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  signOut,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateEmail,
  updatePassword,
  updateProfile,
  User as AuthUser,
  UserCredential,
} from 'firebase/auth'

import { auth } from '../firebase'

const provider = new GoogleAuthProvider()
provider.addScope('https://www.googleapis.com/auth/contacts.readonly')

const AuthContext = React.createContext<AuthValue>({
  authUser: null,
  loading: true,
  loginWithGoogle: () => {},
  loginWithEmail: () => {},
  signup: () => {},
  logout: () => {},
  resetPassword: () => {},
  updateEmail: () => {},
  updatePassword: () => {},
} as any)

export const useAuth = () => React.useContext(AuthContext)

export default function AuthProvider({ children }: { children: React.ReactNode }) {
  const [authUser, setAuthUser] = React.useState<AuthUser | null>(null)
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    if (auth) {
      auth.onAuthStateChanged(authUser => {
        // Setting authUser must come first, or bad refresh state will occur
        setAuthUser(authUser)
        setLoading(false)
      })
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        authUser,
        loading,
        loginWithEmail: (email: string, password: string) =>
          signInWithEmailAndPassword(auth, email, password),
        loginWithGoogle: () =>
          signInWithPopup(auth, provider)
            .then(async result => {
              // This gives you a Google Access Token. You can use it to access the Google API.
              // const credential = GoogleAuthProvider.credentialFromResult(result)
              // const token = credential?.accessToken
              // The signed-in user info.
              setAuthUser(result.user)

              const idToken = await result.user.getIdToken()
              localStorage.setItem('idToken', idToken)
              // ...
            })
            .catch(error => {
              // Handle Errors here.
              const errorCode = error.code
              const errorMessage = error.message
              // The email of the user's account used.
              const email = error.email
              // The AuthCredential type that was used.
              const credential = GoogleAuthProvider.credentialFromError(error)

              console.log(errorCode, errorMessage, email, credential)
              // ...
            }),
        signup: (email: string, password: string) =>
          createUserWithEmailAndPassword(auth, email, password),
        sendSignInLinkToEmail: (email: string) =>
          sendSignInLinkToEmail(auth, email, {
            url: `${process.env.REACT_APP_API_HOST}/orders`,
          }),
        logout: async () => {
          setAuthUser(null)
          signOut(auth)
        },
        resetPassword: (email: string) => sendPasswordResetEmail(auth, email),
        updateEmail: (email: string) => updateEmail(authUser!, email),
        updateProfile: data => updateProfile(authUser!, data),
        updatePassword: (password: string) => updatePassword(authUser!, password),
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

type AuthValue = {
  authUser: AuthUser | null
  loading: boolean
  loginWithEmail: (email: string, password: string) => Promise<UserCredential>
  loginWithGoogle: () => void
  signup: (email: string, password: string) => Promise<UserCredential>
  logout: () => Promise<void>
  resetPassword: (email: string) => Promise<void>
  updateEmail: (email: string) => Promise<void>
  updatePassword: (password: string) => Promise<void>
  updateProfile: (data: Parameters<typeof updateProfile>[1]) => Promise<void>
  sendSignInLinkToEmail: (email: string) => Promise<void>
}
