import { Api } from '.'
import { Inventory } from '../models/inventory'

export default class InventoryApi {
  static async get(id: string) {
    return Api.get<Inventory>(`/inventory/${id}`)
  }
  static async list() {
    return Api.get<Inventory[]>(`/inventory/`)
  }
}
