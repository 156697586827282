export default class Routes {
  static readonly home = '/'
  static readonly login = '/login'
  static readonly signUp = '/sign-up'
  static readonly orders = '/orders'
  static readonly order = '/order'
  static readonly forgotPassword = '/forgot-password'
  static readonly resetPassword = '/reset-password'
  static readonly onboarding = '/onboarding'
  static readonly newOrder = '/new-order'
  static readonly batching = '/batching'
  static readonly batches = '/batches'
}
