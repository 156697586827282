import { useState } from 'react'
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  Text,
  useColorModeValue,
  FormErrorMessage,
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { Link as ReactRouterLink, useNavigate } from 'react-router-dom'
import GoogleButton from '../components/social/GoogleButton'

import Routes from '../constants/routes'
import { useAuth } from '../context/AuthContext'
import PublicRouteLayout from '../layouts/PublicRouteLayout'

export default function Login() {
  const [errorMessage, setErrorMessage] = useState('')

  const { loginWithEmail } = useAuth()

  const navigate = useNavigate()

  type FormData = {
    email: string
    password: string
  }

  const formik = useFormik<FormData>({
    initialValues: { email: '', password: '' },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: yup.object().shape({
      email: yup.string().email().label('Email').required(),
      password: yup.string().label('Password').required(),
    }),
    onSubmit: async ({ email, password }) => {
      try {
        const { user } = await loginWithEmail(email, password)

        const idToken = await user.getIdToken()

        await localStorage.setItem('idToken', idToken)
        if (user) navigate(Routes.orders)
      } catch (error) {
        setErrorMessage((error as any).message)
        console.log((error as any).code)
      }
    },
  })

  return (
    <PublicRouteLayout>
      <Stack spacing={8} mx="auto" maxW="lg" py={12} px={6}>
        <Stack align="center">
          <Heading fontSize="4xl">Sign in to your account</Heading>
          <Text fontSize="lg" color="gray.600">
            to enjoy all of our cool <Link color="blue.400">features</Link> ✌️
          </Text>
        </Stack>
        <Box
          as="form"
          rounded="lg"
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow="lg"
          p={8}
          onSubmit={(e: any) => {
            e.preventDefault()
            formik.handleSubmit()
          }}
        >
          <Stack spacing={4}>
            <FormControl isInvalid={!!formik.errors.email}>
              <FormLabel>Email address</FormLabel>
              <Input
                id="email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel>Password</FormLabel>
              <Input
                id="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                type="password"
              />
              <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errorMessage}>
              <FormErrorMessage>{errorMessage}</FormErrorMessage>
            </FormControl>
            <Stack spacing={10}>
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                align="start"
                justify="space-between"
              >
                <Checkbox>Remember me</Checkbox>
                <Link as={ReactRouterLink} to={Routes.forgotPassword} color="blue.400">
                  Forgot password?
                </Link>
              </Stack>
              <Stack>
                <Button bg="blue.400" color="white" _hover={{ bg: 'blue.500' }} type="submit">
                  Log in
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </PublicRouteLayout>
  )
}
