import { Axios } from 'axios'
import { auth } from '../firebase'

export const Api = new Axios({
  baseURL: process.env.REACT_APP_API_HOST,
})

Api.interceptors.request.use(
  req => {
    // Add configurations here
    const idToken = localStorage.getItem('idToken')

    req.headers = req.headers || {}
    req.headers['Authorization'] = `Bearer ${idToken}`

    return req
  },
  err => {
    return Promise.reject(err)
  },
)

Api.interceptors.response.use(
  async res => {
    // Add configurations heres
    if (res.status === 401 && JSON.parse(res.data)?.message.includes('auth/id-token-expired')) {
      const token = await auth.currentUser?.getIdToken()
      if (token) {
        localStorage.setItem('idToken', token)

        // Axios fails merging this configuration to the default configuration because it has an issue
        // with circular structures: https://github.com/mzabriskie/axios/issues/370
        // fixConfig(axios, config)
        // config.transformRequest = [(data) => data];
        // return new Promise((resolve) => setTimeout(() => resolve(Api(config)), delay));
      }
    }

    if (res.status === 401) {
      localStorage.removeItem('idToken')
      // This is how to remove all authentication from the app
      const req = indexedDB.deleteDatabase('firebaseLocalStorageDb')
      // prettier-ignore
      req.onblocked = () => console.log("Couldn't delete database due to the operation being blocked")
      req.onerror = () => console.log("Couldn't delete database")
      req.onsuccess = () => console.log('Deleted database successfully')
    }

    if (
      typeof res.data === 'string' &&
      res.headers?.['content-type']?.includes('application/json')
    ) {
      res.data = JSON.parse(res.data)
    }

    return res
  },
  err => {
    console.log('HELP!')
    return Promise.reject(err)
  },
)
