import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter as Router, Routes as Switch, Route, Navigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import 'react-popper-tooltip/dist/styles.css'
import 'react-toastify/dist/ReactToastify.css'
import { theme } from './styles/theme'

import PrivateRoute from './components/auth/PrivateRoute'
import PublicRoute from './components/auth/PublicRoute'
import Routes from './constants/routes'
import AuthProvider from './context/AuthContext'
import UserProvider from './context/UserContext'

import Login from './pages/Login'
import SignUp from './pages/SignUp'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import Orders from './pages/orders'
import NewOrder from './pages/new-order'
import NotFound from './pages/404NotFound'
import Batching from './pages/batching'
import OrderPage from './pages/order'

export default function App() {
  return (
    <ChakraProvider theme={theme}>
      <ToastContainer />
      <AuthProvider>
        <UserProvider>
          <Router>
            <Switch>
              {/* Public Routes */}
              <Route
                path={Routes.login}
                element={
                  <PublicRoute>
                    <Login />
                  </PublicRoute>
                }
              />
              <Route
                path={Routes.forgotPassword}
                element={
                  <PublicRoute>
                    <ForgotPassword />
                  </PublicRoute>
                }
              />
              <Route
                path={Routes.resetPassword}
                element={
                  <PublicRoute>
                    <ResetPassword />
                  </PublicRoute>
                }
              />
              <Route path={Routes.home} element={<Navigate replace to={Routes.login} />} />
              {/* Private Routes */}
              <Route
                path={Routes.orders}
                element={
                  <PrivateRoute>
                    <Orders />
                  </PrivateRoute>
                }
              />
              <Route
                path={Routes.newOrder}
                element={
                  <PrivateRoute>
                    <NewOrder />
                  </PrivateRoute>
                }
              />
              <Route
                path={Routes.order}
                element={
                  <PrivateRoute>
                    <OrderPage />
                  </PrivateRoute>
                }
              />

              <Route
                path={Routes.batching}
                element={
                  <PrivateRoute>
                    <Batching />
                  </PrivateRoute>
                }
              />
              {/* 404 */}
              <Route path="*" element={<NotFound />} />
            </Switch>
          </Router>
        </UserProvider>
      </AuthProvider>
    </ChakraProvider>
  )
}
