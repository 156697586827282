import { ReactNode } from 'react'
import {
  Box,
  Flex,
  Avatar,
  HStack,
  Link,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  useColorModeValue,
  Stack,
  Image,
} from '@chakra-ui/react'
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons'
import { useAuth } from '../context/AuthContext'

import logo from '../assets/Logo.png'
import userIcon from '../assets/generic-user-icon.jpg'
import Routes from '../constants/routes'
import { ColorModeSwitcher } from '../ColorModeSwitcher'

const Links = [
  { text: 'New Order', to: Routes.newOrder },
  { text: 'Orders', to: Routes.orders },
  // { text: 'Batching', to: Routes.batching },
  // { text: 'Batches', to: Routes.batches },
]

export default function Navbar({ height }: { height: number }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { logout } = useAuth()

  const links = Links.map(({ to, text }) => (
    <NavLink key={to} href={to}>
      {text}
    </NavLink>
  ))

  return (
    <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4} w="full">
      <Flex h={height} alignItems="center" justifyContent="space-between" position="relative">
        <IconButton
          size="md"
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label="Open Menu"
          display={{ md: 'none' }}
          onClick={isOpen ? onClose : onOpen}
        />
        <HStack spacing={8} alignItems={'center'}>
          <Image src={logo} alt="Pink wood logo" maxH="60px" />
          <HStack as="nav" spacing={4} display={{ base: 'none', md: 'flex' }}>
            {links}
          </HStack>
        </HStack>
        <Flex alignItems="center">
          <Menu>
            <MenuButton as={Button} rounded="full" variant="link" cursor="pointer" minW={0}>
              <Avatar size="sm" src={userIcon} />
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  logout()
                }}
              >
                Log Out
              </MenuItem>
            </MenuList>
          </Menu>
          <ColorModeSwitcher position="absolute" bottom={-16} right={2} />
        </Flex>
      </Flex>

      {isOpen ? (
        <Box pb={4} display={{ md: 'none' }}>
          <Stack as="nav" spacing={4}>
            {links}
          </Stack>
        </Box>
      ) : null}
    </Box>
  )
}

function NavLink({ children, href }: { children: ReactNode; href: string }) {
  return (
    <Link
      px={4}
      py={2}
      rounded="md"
      _hover={{
        textDecoration: 'none',
        bg: useColorModeValue('gray.200', 'gray.700'),
      }}
      href={href}
    >
      {children}
    </Link>
  )
}
