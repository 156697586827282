import { IconButton, useToast } from '@chakra-ui/react'
import { FiUpload } from 'react-icons/fi'
import OrderApi from '../../api/order'
import { Attachment, Order } from '../../models/order'

type Props = {
  order: any
  onUpload?: (attachment: Attachment) => void
}

export default function UploadAttachment({ order, onUpload }: Props) {
  let inputElement: HTMLInputElement | null
  const toast = useToast()

  function toastNotification(
    status: 'success' | 'info' | 'warning' | 'error',
    title: string,
    message: string,
    redirect?: () => void,
  ): void {
    toast({
      title: title,
      description: message,
      status: status,
      duration: 5000,
      isClosable: true,
      onCloseComplete: () => redirect && redirect(),
    })
  }

  function filesSetEvent(event: any, order: Order) {
    let file = event.target.files[0]

    const formData = new FormData()
    formData.append('File', file)
    toastNotification('info', 'Uploading File', 'Uploading file to server.')
    OrderApi.uploadFile(order, formData)
      .then(res => {
        if (res.status === 201) {
          if (onUpload != null) onUpload(res.data as Attachment)
          toastNotification('success', 'File Uploaded', 'File was successfully uploaded to server.')
        } else {
          toastNotification('error', 'Uploading File', 'File upload failed.')
        }
      })
      .catch(() => toastNotification('error', 'Uploading File', 'File upload failed.'))
  }

  function uploadFile(): void {
    inputElement?.click()
  }

  return (
    <span>
      <input
        hidden
        ref={input => (inputElement = input)}
        type="file"
        name="invoice"
        onChange={event => {
          filesSetEvent(event, order)
        }}
      />
      <IconButton aria-label="Upload Invoice" icon={<FiUpload />} onClick={() => uploadFile()} />
    </span>
  )
}
