import {
  Box,
  Button,
  Fade,
  Flex,
  Heading,
  SlideFade,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import PublicRouteLayout from '../layouts/PublicRouteLayout'

export default function NotFound() {
  const textColor = useColorModeValue('gray.600', 'gray.200')

  return (
    <PublicRouteLayout>
      <Stack spacing={5} mx="auto" maxW="4xl" py={12} px={6}>
        <SlideFade in offsetY="60px" transition={{ enter: { duration: 0.9, delay: 0.5 } }}>
          <Text textAlign="center" fontSize="4xl" color={textColor} pb="5">
            Whoops!
          </Text>
        </SlideFade>
        <Box rounded="lg" bg={useColorModeValue('white', 'gray.700')} boxShadow="lg" p={8}>
          <Heading fontSize="6xl" textAlign="center">
            404
          </Heading>
          <Text textAlign="center" fontSize="2xl" color={textColor} pb="5">
            Page not found
          </Text>
          <Text textAlign="center" fontSize="2xl" color={textColor}>
            We can&apos;t seem to find the page you&apos;re looking for
          </Text>
        </Box>
        <Flex justifyContent="center">
          <Fade in transition={{ enter: { duration: 0.9, delay: 1.5 } }}>
            <Button
              bg="blue.400"
              color="white"
              _hover={{
                bg: 'blue.500',
              }}
              onClick={() => window.history.back()}
            >
              Return to Previous Page
            </Button>
          </Fade>
        </Flex>
      </Stack>
    </PublicRouteLayout>
  )
}
