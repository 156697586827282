import { Item } from './item'

export class Inventory {
  public id: string
  public item: Item
  public series: string
  public depth: string
  public length: string
  public piecesPerUnit: number
  public weight: number
  public price: number

  constructor(
    id: string,
    item: Item,
    series: string,
    depth: string,
    length: string,
    piecesPerUnit: number,
    weight: number,
    price: number,
  ) {
    this.id = id
    this.item = item
    this.series = series
    this.depth = depth
    this.length = length
    this.piecesPerUnit = piecesPerUnit
    this.weight = weight
    this.price = price
  }
}
