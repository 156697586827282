import { Company } from './company'
import { LineItem } from './lineItem'

export enum OrderStatus {
  RECEIVED = 'Received',
  CONFIRMED = 'Confirmed',
  SCHEDULED_FOR_SHIPPING = 'Scheduled for Shipping',
  IS_NOT_IN_PRODUCTION = 'Is Not in Production',
  IS_IN_PRODUCTION = 'Is in Production',
  SHIPPED = 'Shipped',
}

export enum FreightType {
  TRIAXLE = 'Tri-Axle',
  RAILCAR = 'Rail-Car',
}

export class Attachment {
  public id?: string
  public order_token?: string
  public file_name?: string
  public attachment?: any
}

export class OrderCompanyRelation {
  public id?: number
  public company?: Company
  public order?: Order
}

export class Order {
  public id?: string
  public status: OrderStatus = OrderStatus.RECEIVED //enum
  public weight = 0
  public grossWeight = 0
  public loadBuilderStatus = 0
  public wasInvoiced = false

  public purchaseOrderNo?: string
  public freightType?: string
  public user_token?: string
  public updatedAt: Date
  public createdAt: Date
  // public address1?: string
  // public address2?: string
  // public city?: string
  // public stateToShipTo?: string
  // public zip?: number
  public instructions?: string
  public orderWholesalersCustomer?: OrderCompanyRelation
  public orderRetailersCustomer?: OrderCompanyRelation
  public wholesalerCustomer?: string
  public retailerCustomer?: string
  public checked: boolean
  public lineItems?: LineItem[]
  public attachments?: Attachment[]
  public taxes?: number
  public freight?: number

  constructor() {
    this.updatedAt = new Date()
    this.createdAt = new Date()
    this.checked = false
  }

  loadDeliveryFormData(
    poNum: string,
    status: OrderStatus = OrderStatus.RECEIVED,
    freightType: string = FreightType.TRIAXLE,
    // address1: string,
    // address2: string,
    // city: string,
    // stateToShipTo: string,
    // zip: number,
    instructions: string,
    user_token: string,
    wholesaler?: OrderCompanyRelation,
    retailer?: OrderCompanyRelation,
    id?: string,
    taxes?: number,
    freight?: number,
  ) {
    this.purchaseOrderNo = poNum
    this.status = status
    this.freightType = freightType
    // this.address1 = address1
    // this.address2 = address2
    // this.city = city
    // this.stateToShipTo = stateToShipTo
    // this.zip = zip
    this.instructions = instructions
    this.orderWholesalersCustomer = wholesaler
    this.orderRetailersCustomer = retailer
    this.user_token = user_token
    this.taxes = taxes
    this.freight = freight

    if (id) {
      this.id = id
    }
  }
}
