import { Api } from '.'
import { Attachment, Order } from '../models/order'

export default class OrderApi {
  static uploadFile(order: Order, file: FormData) {
    return Api.post<File>(`/order/attach/${order.id}`, file, {
      headers: { 'Content-Type': 'application/json' },
    })
  }

  static deleteAttachment(attachmentId: string) {
    return Api.delete<Attachment>(`/order/attach/${attachmentId}`)
  }

  static getAttachment(attachmentId: string) {
    return Api.get<Attachment>(`/order/attach/${attachmentId}`)
  }

  static async get(id: string) {
    return Api.get<Order>(`/order/${id}`)
  }

  static async create(order: Order) {
    return Api.post<Order>(`/order/`, JSON.stringify(order), {
      headers: { 'Content-Type': 'application/json' },
    })
  }

  static async update(order: Order) {
    return Api.patch<Order>(`/order/${order.id}`, JSON.stringify(order), {
      headers: { 'Content-Type': 'application/json' },
    })
  }

  static async list() {
    return Api.get<Order[]>('/order/')
  }

  static async remove(order: Order) {
    return Api.delete<Order>(`/order/${order.id}`)
  }
}
