import { Api } from '.'

export default class UserApi {
  static async get(uid: string) {
    return Api.get<User>(`/user/${uid}`)
  }

  static async patch(values: UpdateUserValues) {
    return Api.patch<{ success: boolean }>(`/user/${values.uid}`, values)
  }
}

export type User = {
  uid: string
  email: string
  firstName: string
  lastName: string
}

type UpdateUserValues = Partial<User> & { uid: string }
