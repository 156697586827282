import React from 'react'
import { Box, Flex, useColorModeValue, useToken } from '@chakra-ui/react'

import Navbar from './Navbar'

const height = 20

export default function PrivateRouteLayout(props: { children: React.ReactNode }) {
  const navbarHeight = useToken('space', height)

  return (
    <Flex
      minH="100vh"
      h="full"
      direction="column"
      bg={useColorModeValue('gray.50', 'gray.800')}
      position="relative"
    >
      <Navbar height={height} />
      <Box w="full" h={`calc(100vh - ${navbarHeight})`}>
        {props.children}
      </Box>
    </Flex>
  )
}
