import React from 'react'
import { Box, Flex, useColorModeValue } from '@chakra-ui/react'

import { ColorModeSwitcher } from '../ColorModeSwitcher'

export default function PublicRouteLayout(props: { children: React.ReactNode }) {
  return (
    <Flex
      minH="100vh"
      align="center"
      justify="center"
      bg={useColorModeValue('gray.50', 'gray.800')}
      position="relative"
    >
      <Box position="absolute" top={4} right={4}>
        <ColorModeSwitcher />
      </Box>
      {props.children}
    </Flex>
  )
}
