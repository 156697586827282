import { Inventory } from './inventory'

export class LineItem {
  public id?: string
  public series_id?: string
  public lifts?: number
  public pieces?: number
  public order_token?: string
  public series?: Inventory
  public quantity = 1
  public price?: number

  loadLineItemFromData(
    id: string,
    series_id: string,
    lifts: number,
    pieces: number,
    order_token?: string,
    price?: number,
  ) {
    this.id = id
    this.series_id = series_id
    this.lifts = lifts
    this.pieces = pieces
    this.order_token = order_token
    this.price = price
  }

  calculateWeight(): number {
    return 4
  }

  calculatePrice(): number {
    return 4
  }
}
