import React from 'react'
import { Box, Button, Divider, HStack, VStack, Text, useColorModeValue } from '@chakra-ui/react'
import { Inventory } from '../../../models/inventory'
import { LineItem } from '../../../models/lineItem'
import { Item } from '../../../models/item'

type Props = {
  addRow: (newRow: LineItem) => void
  seriesData: Inventory[]
  itemData: Item[]
}

export default function AddLineForm({ addRow, seriesData, itemData }: Props) {
  const [series, setSeries] = React.useState<string | undefined>()
  const [joistDepth, setJoistDepth] = React.useState<string | undefined>()
  const [length, setLength] = React.useState<string | undefined>()
  const [_inventoryItem, setInventoryItem] = React.useState<Inventory | undefined>()
  const [_item, setItem] = React.useState<string | undefined>()

  const textColor = useColorModeValue('gray.800', 'whiteAlpha.900')
  const tooltipColor = useColorModeValue('gray.50', 'gray.800')
  const borderColor = useColorModeValue('gray.300', 'gray.600')

  function handleFinish(newLength: string, newItem: any) {
    let lineItem: LineItem = new LineItem()
    let inventoryItem: Inventory = new Inventory(
      newItem.id,
      newItem.item,
      newItem.series,
      newItem.depth,
      newItem.length,
      newItem.piecesPerUnit,
      newItem.weight,
      newItem.price,
    )
    lineItem.lifts = newItem.lifts
    lineItem.pieces = newItem.piecesPerUnit
    lineItem.series_id = newItem?.id
    lineItem.series = inventoryItem
    lineItem.price = 0
    // Save to table
    addRow(lineItem)
    // Reset form
    resetForm()
  }

  function resetForm() {
    setItem(undefined)
    setSeries(undefined)
    setJoistDepth(undefined)
    setLength(undefined)
    setInventoryItem(undefined)
  }

  return (
    <HStack align="start">
      <VStack
        borderRadius="lg"
        bg={tooltipColor}
        borderColor={borderColor}
        borderWidth="1px"
        p={2}
        boxShadow="md"
      >
        <Text color={textColor}>Item</Text>
        <Divider />
        {[...new Set(itemData)]
          .reduce((prev, current) => {
            prev.push(current)
            return prev
          }, [] as Item[])
          .map((item, index, inventory) => (
            <React.Fragment key={item.itemName + item.id}>
              <OptionButton
                text={item.itemName}
                onClick={() => {
                  setItem(item.id)
                }}
                isActive={_item === item.id}
                textColor={textColor}
              />
            </React.Fragment>
          ))}
      </VStack>
      {_item && (
        <VStack
          borderRadius="lg"
          bg={tooltipColor}
          borderColor={borderColor}
          borderWidth="1px"
          p={2}
          boxShadow="md"
        >
          <Text color={textColor}>Series</Text>
          <Divider />
          {[...new Set(seriesData)]
            .reduce((prev, current) => {
              if (
                current.item &&
                current.item.id === _item &&
                !prev.find(inventoryItem => inventoryItem.series === current.series)
              ) {
                prev.push(current)
              }
              return prev
            }, [] as Inventory[])
            .map((item, index, inventory) => (
              <React.Fragment key={item.series + item.id}>
                <OptionButton
                  text={item.series}
                  onClick={() => {
                    setSeries(item.series)
                  }}
                  isActive={series === item.series}
                  textColor={textColor}
                />
                {/* interpose between every element */}
                {index !== inventory.length - 1 && <Divider />}
              </React.Fragment>
            ))}
        </VStack>
      )}
      {series && (
        <VStack
          bg={tooltipColor}
          borderColor={borderColor}
          p={1.5}
          borderWidth="1px"
          borderRadius="lg"
        >
          <Text color={textColor}>Joist&nbsp;Depth</Text>
          <Divider />
          {seriesData
            // .filter(items => {
            //   console.log('items', items);
            //   return items.series === series;
            // })
            .reduce((prev, current) => {
              if (
                current.series === series &&
                !prev.find(inventoryItem => inventoryItem.depth === current.depth)
              ) {
                prev.push(current)
              }
              return prev
            }, [] as Inventory[])
            .map((item, index, inventory) => (
              <React.Fragment key={item.depth + item.id}>
                <OptionButton
                  text={item.depth}
                  onClick={() => {
                    setJoistDepth(item.depth)
                  }}
                  isActive={joistDepth === item.depth}
                  textColor={textColor}
                />
                {/* interpose between every element */}
                {index !== inventory.length - 1 && <Divider />}
              </React.Fragment>
            ))}
        </VStack>
      )}
      {series && joistDepth && (
        <VStack
          bg={tooltipColor}
          borderColor={borderColor}
          p={1.5}
          borderWidth="1px"
          borderRadius="lg"
          maxHeight="300px"
          overflowY="scroll"
        >
          <Text color={textColor}>Length</Text>
          <Divider />
          {seriesData
            // .filter(items => items.series === series && items.depth === joistDepth)
            .reduce((prev, current) => {
              if (
                current.series === series &&
                current.depth === joistDepth &&
                !prev.find(inventoryItem => inventoryItem.length === current.length)
              ) {
                prev.push(current)
              }
              return prev
            }, [] as Inventory[])
            .map((item, index, inventory) => (
              <React.Fragment key={item.length + item.id}>
                <OptionButton
                  text={item.length}
                  onClick={() => {
                    setLength(item.length)
                    setInventoryItem(item)
                    handleFinish(item.length, item)
                  }}
                  isActive={length === item.length}
                  textColor={textColor}
                />
                {/* interpose between every element */}
                {index !== inventory.length - 1 && <Divider />}
              </React.Fragment>
            ))}
        </VStack>
      )}
    </HStack>
  )
}

type OptionButtonProps = {
  text: string
  onClick: () => void
  isActive: boolean
  textColor: string
}

function OptionButton({ text, onClick, isActive, textColor }: OptionButtonProps) {
  return (
    <Box>
      <Button onClick={onClick} isActive={isActive}>
        <Text color={textColor}>{text}</Text>
      </Button>
    </Box>
  )
}
