/* eslint-disable react/jsx-key */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Checkbox } from '@chakra-ui/checkbox'
import { Flex, HStack, Text, VStack } from '@chakra-ui/layout'
import { Select } from '@chakra-ui/select'
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/input'
import { Button, IconButton } from '@chakra-ui/button'
import { SearchIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons'
import { useToast } from '@chakra-ui/react'
import { TableOptions } from 'react-table'

import PrivateRouteLayout from '../../layouts/PrivateRouteLayout'
import { Order, OrderStatus } from '../../models/order'
import OrderApi from '../../api/order'
import Routes from '../../constants/routes'
import UploadAttachment from '../order/UploadAttachment'
import { Table } from '../../components/table/table'
import {
  BasicTableDataRow,
  TableCellContentAlignEnum,
  TableDataRow,
} from '../../components/table-row/HeaderItem'
import { useAuth } from '../../context/AuthContext'

// TODO
// Get the search working.
// Get the edit status to work.
// Display the line items
// Edit order

export default function Orders() {
  const [orderData, setData] = useState<Order[]>([])
  const [atLeastOneSelected, setAtLeastOneSelected] = useState(false)

  useEffect(() => {
    async function fetchData() {
      const res = await OrderApi.list()
      if (res.status === 200) {
        const newData: Order[] = res.data ? res.data : []
        setData(newData)
      } else {
        setData([])
      }
    }
    fetchData()
  }, [])

  const columns = [
    {
      id: 'checkbox-table-column',
      width: 25,
      Cell: ({ row }: { row: Order & { index: number } }) => (
        <Checkbox
          onChange={(e: any) => {
            orderData[row.index].checked = e.target.checked
            setData(orderData)
            setAtLeastOneSelected(orderData.some(d => d.checked))
          }}
        />
      ),
    },
    {
      Header: 'Date Ordered',
      accessor: 'updatedAt',
    },
    {
      Header: 'Wholesaler',
      accessor: 'client',
    },
    {
      Header: 'Retailer',
      accessor: 'retailer',
    },
    {
      Header: 'PO Number',
      accessor: 'purchaseOrderNo',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Details',
      accessor: 'details',
    },
  ]

  function removeOrder(order: Order) {
    let idx = orderData.findIndex(orderCandidate => orderCandidate.id === order.id)
    let dataCopy: Order[] = [...orderData]
    dataCopy.splice(idx, 1)
    setData(dataCopy)
  }

  return (
    <PrivateRouteLayout>
      <VStack align="start" mx="20" mt="20" justifyContent="space-around">
        <CustomTable
          columns={columns as any}
          data={orderData}
          // statusChoice={statusChoice}
          // setStatusChoice={setStatusChoice}
          removeOrderCallback={order => removeOrder(order)}
          atLeastOneSelected={atLeastOneSelected}
          setData={setData}
        />
      </VStack>
    </PrivateRouteLayout>
  )
}

type CustomTableProps<D extends Order> = {
  // setStatusChoice: (statusChoice: OrderStatus | undefined) => void
  // statusChoice: OrderStatus | undefined
  atLeastOneSelected: boolean
  removeOrderCallback: (order: Order) => void
  setData: (data: Order[]) => void
} & TableOptions<D>

function CustomTable<D extends Order>(props: CustomTableProps<D>) {
  const {
    columns,
    data,
    // setStatusChoice, statusChoice,
    removeOrderCallback,
    atLeastOneSelected,
    setData,
  } = props
  // const [orderData, setData] = useState<Order[]>([])
  const { authUser } = useAuth()
  const [statusChoice, setStatusChoice] = useState<OrderStatus | undefined>()

  const toast = useToast()

  function toastNotification(
    status: 'success' | 'info' | 'warning' | 'error',
    title: string,
    message: string,
  ): void {
    toast({
      title: title,
      description: message,
      status: status,
      duration: 9000,
      isClosable: true,
    })
  }

  function deleteInvoice(order: Order): void {
    OrderApi.remove(order).then(res => {
      if (res.status === 200) {
        removeOrderCallback(order)
        toastNotification('success', 'Delete Order', `Deleting order succeeded`)
      } else {
        toastNotification(
          'error',
          'Delete Order',
          `Deleting order failed with error: ${res.statusText}`,
        )
      }
    })
  }

  const orderStatusKeys = Object.keys(OrderStatus) as Array<keyof typeof OrderStatus>
  const navigate = useNavigate()

  const handleChangeStatus = (e: any, id: string) => {
    console.log('handleChangeStatus', e.target.value, id)
    let newOrder = new Order()
    const order = data.find(order => order.id === id)

    if (order == null) throw Error('Order number not set.')

    newOrder.loadDeliveryFormData(
      order.purchaseOrderNo || '',
      e.target.value,
      order.freightType,
      order.instructions || '',
      authUser!.uid,
      order.orderWholesalersCustomer,
      order.orderRetailersCustomer,
      order.id,
    )
    OrderApi.update(newOrder).then(res => {
      if (res.status === 200) {
        OrderApi.list().then(res => {
          if (res.status === 200) {
            const newData: Order[] = res.data ? res.data : []
            console.log(newData)
            setData(newData)
          } else {
            setData([])
          }
          toastNotification('success', 'Update Order', `Updating order succeeded`)
        })
      } else {
        toastNotification(
          'error',
          'Update Order',
          `Updating order failed with error: ${res.statusText}`,
        )
      }
    })
  }

  const filteredData = data.filter((order: any) => {
    if (statusChoice === undefined) return true
    return order.status === statusChoice
  })

  const [searchValue, setSearchValue] = useState('')

  const dataFilteredBySearch = filteredData.filter((order: any) => {
    if (searchValue === '') return true
    console.log(order)
    return (
      order.purchaseOrderNo.toLowerCase().includes(searchValue.toLowerCase()) ||
      order.orderWholesalersCustomer?.company?.name
        ?.toLowerCase()
        .includes(searchValue.toLowerCase()) ||
      order.orderRetailersCustomer?.company?.name
        ?.toLowerCase()
        .includes(searchValue.toLowerCase()) ||
      order.details?.toLowerCase().includes(searchValue.toLowerCase())
    )
  })

  const handleSearchChange = (e: any) => {
    setSearchValue(e.target.value)
  }

  return (
    <>
      <HStack w="full">
        <Text as="h2" fontSize="3xl" fontWeight="semibold" pb="4" w="full">
          ORDER
        </Text>
        <Flex justifyContent="space-between" m={4} alignItems="center">
          <HStack w="full">
            <Button disabled={!statusChoice || !atLeastOneSelected}>Change</Button>
            <Select
              placeholder="Select status"
              size="md"
              w="60"
              onChange={e => {
                const keyIndex =
                  orderStatusKeys[Object.values(OrderStatus).indexOf(e.target.value as any)]
                setStatusChoice(OrderStatus[keyIndex])
              }}
            >
              {orderStatusKeys.map(key => (
                <option key={key} value={OrderStatus[key]}>
                  {OrderStatus[key]}
                </option>
              ))}
            </Select>
            <InputGroup size="md" w="60">
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.300" />
              </InputLeftElement>
              <Input placeholder="Search" value={searchValue} onChange={handleSearchChange} />
            </InputGroup>
          </HStack>
        </Flex>
      </HStack>
      <Table
        headerList={[
          {
            id: 'checkbox',
            headerLabel: <Checkbox />,
            alignment: TableCellContentAlignEnum.CENTER,
            headerStyles: { maxWidth: '200px' },
          },
          {
            id: 'updatedAt',
            headerLabel: 'Date Ordered',
          },
          {
            id: 'orderWholesalersCustomer',
            headerLabel: 'Wholesaler',
          },
          {
            id: 'orderRetailersCustomer',
            headerLabel: 'Retailer',
          },
          {
            id: 'purchaseOrderNo',
            headerLabel: 'PO Number',
          },
          {
            id: 'status',
            headerLabel: 'Status',
          },
          {
            id: 'details',
            headerLabel: 'Details',
          },
          {
            id: 'actions',
            headerLabel: 'Actions',
          },
        ]}
        dataList={dataFilteredBySearch as unknown as BasicTableDataRow[]}
        mapData={(data: TableDataRow<any>) => {
          return {
            ...data,
            status: (
              <Select
                placeholder="Select status"
                size="md"
                w="60"
                value={data.status}
                onChange={e => handleChangeStatus(e, data.id)}
              >
                {orderStatusKeys.map(key => (
                  <option key={key} value={OrderStatus[key]}>
                    {OrderStatus[key]}
                  </option>
                ))}
              </Select>
            ),
            orderRetailersCustomer: data.orderRetailersCustomer?.company?.name,
            orderWholesalersCustomer: data.orderWholesalersCustomer?.company?.name,
            checkbox: <Checkbox />,
            actions: (
              <>
                <IconButton
                  aria-label="Edit Order"
                  icon={<EditIcon />}
                  onClick={() =>
                    navigate(
                      { pathname: Routes.order, search: `orderUid=${data.id}` },
                      { replace: true },
                    )
                  }
                />
                <UploadAttachment order={data} />
                <IconButton
                  colorScheme="red"
                  aria-label="Delete Invoice"
                  icon={<DeleteIcon />}
                  onClick={() => deleteInvoice(data)}
                />
              </>
            ),
          }
        }}
      />
    </>
  )
}
