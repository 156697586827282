import * as React from 'react'
import { CSSProperties, ReactNode } from 'react'

export interface BasicTableDataRow {
  id: string
  actions?: ReactNode
  [key: string]: ReactNode | Date | string | number
}

export enum TableCellContentAlignEnum {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

export enum ButtonTypeEnum {
  Link = 'Link',
  button = 'button',
  a = 'a',
  ref = 'ref',
}

export type TableDataRow<T extends BasicTableDataRow> = T

export type TableHeaderItem = {
  id: string
  headerStyles?: CSSProperties
  headerLabel: ReactNode
  alignment?: TableCellContentAlignEnum
  contentType?: ButtonTypeEnum
  pathPattern?: string
}

export interface HeaderItemProps<T extends BasicTableDataRow> {
  headerItem: TableHeaderItem
  row: TableDataRow<T>
}

export const HeaderItem = <T extends BasicTableDataRow>({
  headerItem,
  row,
}: HeaderItemProps<T>) => {
  return (
    <>
      <dt className="sr-only">{headerItem.headerLabel}</dt>
      <dd className="mt-1 truncate text-gray-700">{row[headerItem.id]}</dd>
    </>
  )
}

export default HeaderItem
