import { useSearchParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  HStack,
  Select,
  Stack,
  Textarea,
  Text,
  CircularProgress,
  useToast,
  useColorModeValue,
} from '@chakra-ui/react'
import { MdArrowDropDown } from 'react-icons/md'
import { Formik, useFormik } from 'formik'
import * as yup from 'yup'

import {
  Attachment,
  FreightType,
  Order,
  OrderCompanyRelation,
  OrderStatus,
} from '../../models/order'
import OrderApi from '../../api/order'
import { useAuth } from '../../context/AuthContext'
import PrivateRouteLayout from '../../layouts/PrivateRouteLayout'
import { Inventory } from '../../models/inventory'
import { LineItem } from '../../models/lineItem'
import InventoryApi from '../../api/inventory'

import { usePopperTooltip } from 'react-popper-tooltip'
import { AddIcon } from '@chakra-ui/icons'

import AddLineForm from '../new-order/order-form/AddLineForm'

import Table from '../new-order/order-form/table/OrderTable'
import OrderLineItemApi from '../../api/orderLineItem'
import AttachmentWidget from './AttachmentWidget'
import UploadAttachment from './UploadAttachment'
import { Company } from '../../models/company'
import CompanyApi from '../../api/company'
import { Parser } from '@json2csv/plainjs'
import { Item } from '../../models/item'
import ItemApi from '../../api/item'

export default function OrderPage() {
  const [searchParams] = useSearchParams({})
  const [_orderUid, setOrderUid] = useState<string>()
  const [order, setOrder] = useState<Order>()
  const { authUser } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [_wholesalers, setWholesalers] = useState<Company[]>([])
  const [_retailers, setRetailers] = useState<Company[]>([])
  const toast = useToast()
  const [data, setData] = React.useState<LineItem[]>([])
  const [mounted, setMounted] = React.useState(false)
  const [inventoryData, setInventoryData] = React.useState<Inventory[]>([])
  const [itemData, setItemData] = React.useState<Item[]>([])
  const [orderStatus, setOrderStatus] = useState<OrderStatus>(OrderStatus.RECEIVED)

  useEffect(() => {
    async function init() {
      const itemRes = await ItemApi.list()
      setItemData(itemRes.data)
      const res = await InventoryApi.list()
      setInventoryData(res.data)
    }
    init()
  }, [])

  useEffect(() => {
    async function fetchOrderData(id: string) {
      const order: Order = (await OrderApi.get(id)).data
      setOrder(order)
      if (order.lineItems) {
        const lineItems = await OrderLineItemApi.getByOrder(order.id!)
        setData(lineItems.data)
      }
    }
    const id = searchParams.get('orderUid')
    if (id != null) {
      setOrderUid(id!)
      fetchOrderData(id)
    }
  }, [searchParams])

  useEffect(() => {
    async function init() {
      const wholesalerRes = await CompanyApi.getWholesalers()
      setWholesalers(wholesalerRes.data)
      if (order?.orderWholesalersCustomer?.company?.id) {
        const retailerRes = await CompanyApi.getRetailersByWholesaler(
          order.orderWholesalersCustomer.company.id,
        )
        setRetailers(retailerRes.data)
      }
    }
    init()
  }, [order])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Series Id',
        accessor: 'series_id',
      },
      {
        Header: 'Series',
        accessor: 'series.series',
      },
      {
        Header: 'Joist Depth',
        accessor: 'series.depth',
      },
      {
        Header: 'Length (ft.)',
        accessor: 'series.length',
      },
      {
        Header: 'Lifts',
        accessor: 'quantity',
      },
      {
        Header: 'Remaining Pieces',
        accessor: 'remaining_pieces',
      },
      {
        Header: 'Total Pieces',
        accessor: 'pieces',
      },
      {
        Header: 'Pieces Per Lift',
        accessor: 'series.piecesPerUnit',
      },
      {
        Header: 'Weight',
        accessor: 'series.weight',
        showLabel: true,
      },
      {
        Header: 'Price',
        accessor: 'price',
        showTotal: true,
      },
      {
        Header: 'Del.',
        accessor: '',
        hideHeader: true,
      },
    ],
    [],
  )

  //   const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
  //     trigger: 'click',
  //     interactive: true,
  //     onVisibleChange: (visible: boolean) => {
  //       if (!mounted && visible) setMounted(true)
  //     },
  //   })

  type FormData = {
    po_number: string
    // line_1: string
    // line_2: string
    // city: string
    // state: string
    // zip?: number
    instructions?: string
    freightType: string
    wholesaler?: OrderCompanyRelation
    retailer?: OrderCompanyRelation
    status?: OrderStatus
    freight?: number
    taxes?: number
  }

  async function fetchRetailers(wholesalerId: string | null = null) {
    if (wholesalerId) {
      const res = await CompanyApi.getRetailersByWholesaler(wholesalerId)
      setRetailers(res.data)
    }
  }

  function updateOrderLines(newRow: LineItem): void {
    debugger
    newRow.order_token = order?.id

    OrderLineItemApi.create(newRow).then(res => {
      if (res.status === 201) {
        setData([...data, res.data])
        toastNotification(
          'success',
          'Order Line Item Created.',
          'Order Line Item successfully created.',
        )
      } else {
        toastNotification(
          'error',
          'Order Line Item Not Created',
          'Something went wrong with creating order line item: ' + res.statusText,
        )
      }
    })
  }

  function removeOrderLine(removedRow: LineItem, index: number): void {
    OrderLineItemApi.remove(removedRow).then(res => {
      if (res.status === 200) {
        setData(data.filter((_, i) => i !== index))
        toastNotification(
          'success',
          'Order Line Item Deleted',
          'Order Line Item successfully deleted',
        )
      } else {
        toastNotification(
          'error',
          'Order Line Item Not Deleted',
          'Something went wrong with deleting order line item: ' + res.statusText,
        )
      }
    })
  }

  async function updateOrderLine(updatedRow: LineItem): Promise<any> {
    return OrderLineItemApi.update(updatedRow)
  }

  function toastNotification(
    status: 'success' | 'info' | 'warning' | 'error',
    title: string,
    message: string,
  ): void {
    toast({
      title: title,
      description: message,
      status: status,
      duration: 9000,
      isClosable: true,
    })
  }

  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    trigger: 'click',
    interactive: true,
    onVisibleChange: (visible: boolean) => {
      if (!mounted && visible) setMounted(true)
    },
  })

  const tooltipColor = useColorModeValue('gray.50', 'gray.800')

  function sendOrder(values: any): void {
    let newOrder = new Order()

    if (order == null) throw Error('Order number not set.')

    newOrder.loadDeliveryFormData(
      values.po_number,
      orderStatus,
      values.freightType,
      values.instructions || '',
      authUser!.uid,
      values.wholesaler,
      values.retailer,
      order.id,
      values.taxes,
      values.freight,
    )
    newOrder.wholesalerCustomer = values.wholesaler
    newOrder.retailerCustomer = values.retailer
    OrderApi.update(newOrder).then(res => {
      console.log(res.status)
      if (res.status === 200) {
        toastNotification(
          'success',
          'Order updated.',
          `Order ${order.purchaseOrderNo} was successfully updated.`,
        )
      }
    })
  }

  if (!order) return <div>Loading...</div>
  return (
    <PrivateRouteLayout>
      <Formik
        initialValues={{
          po_number: order ? order.purchaseOrderNo! : '',
          instructions: order ? order.instructions! : '',
          freightType: order ? order.freightType! : '',
          wholesaler: order?.orderWholesalersCustomer,
          retailer: order?.orderRetailersCustomer,
          status: orderStatus,
          freight: order?.freight + '' || '',
          taxes: order?.taxes + '' || '',
        }}
        validationSchema={yup.object().shape({
          po_number: yup.string().label('PO Number').required(),
          instructions: yup.string().label('Instructions').optional().nullable(),
          freightType: yup.string().label('Freight Type').required(),
          wholesaler: yup.string().label('Wholesaler').required(),
          retailer: yup.string().label('Retailer').optional(),
          status: yup.string().label('Status').required(),
          taxes: yup.string().label('Taxes').required(),
          freight: yup.string().label('Freight').required(),
        })}
        onSubmit={values => {
          try {
            setIsLoading(true)
            sendOrder(values) //This may change with the new multipage
            //Go to lineItems.
          } catch {
            toastNotification('error', 'Error', 'Something went wrong.')
          } finally {
            setIsLoading(false)
          }
          //Check if create to reset the form
        }}
      >
        {formik => {
          const onDownloadCSV = () => {
            try {
              const parser = new Parser()

              const newOrder = {
                ...formik.values,
                retailer: formik.values.retailer ? `${formik.values.retailer?.company?.name} ` : '',
                retailerAddress: formik.values.retailer
                  ? `${formik.values.retailer?.company?.address1}${
                      formik.values.retailer?.company?.address2
                        ? ', ' + formik.values.retailer.company.address2
                        : ''
                    }, ${formik.values.retailer?.company?.city}, ${
                      formik.values.retailer?.company?.state
                    }, ${formik.values.retailer?.company?.zip}`
                  : '',
                wholesaler: formik.values.wholesaler
                  ? `${formik.values.wholesaler?.company?.name} `
                  : '',
                wholesalerAddress: formik.values.wholesaler
                  ? `${formik.values.wholesaler?.company?.address1}${
                      formik.values.wholesaler?.company?.address2
                        ? ', ' + formik.values.wholesaler.company.address2
                        : ''
                    }, ${formik.values.wholesaler?.company?.city}, ${
                      formik.values.wholesaler?.company?.state
                    }, ${formik.values.wholesaler?.company?.zip}`
                  : '',
              }

              const dataToConvertToCSV = data.map((item: LineItem) => {
                return {
                  ...newOrder,
                  series: item.series?.series,
                  joistDepth: item.series?.depth,
                  length: item.series?.length,
                  lifts: Math.floor((item?.pieces || 0) / (item?.series?.piecesPerUnit || 0)),
                  extraPieces: (item.pieces || 0) % (item.series?.piecesPerUnit || 0),
                  totalPieces: item.pieces,
                  weight: (item.series?.weight || 0) * (item.pieces || 0),
                  price: item.price,
                }
              })

              const csv = parser.parse(dataToConvertToCSV)
              const contentType = 'text/csv'
              const element = document.createElement('a')
              const file = new Blob([csv], { type: contentType })
              element.href = URL.createObjectURL(file)
              element.download = 'myFile.csv'
              document.body.appendChild(element) // Required for this to work in FireFox
              element.click()
            } catch (err) {
              console.error(err)
            }
          }

          return (
            <form onSubmit={formik.handleSubmit}>
              <HStack align="start" mx="20" mt="20" justifyContent="space-around">
                <Stack direction={['column', 'row']} spacing="5%">
                  {/* <Stack>
              <Stack>
                <Text as="h2" fontSize="3xl" fontWeight="semibold" pb="4">
                  USER DATA
                </Text>
              </Stack>
              <Stack spacing={4}>
                <FormControl isInvalid={!!formik.errors.wholesalerUid}>
                  <FormLabel>Wholesaler</FormLabel>
                  <Select
                    id="wholesaler"
                    placeholder="Select Wholesaler"
                    icon={<MdArrowDropDown />}
                    // value={formik.values.wholesalerUid}
                    onChange={e => {
                      formik.setFieldValue('wholesalerUid', e.target.value)
                      // if (e.target.value != null && e.target.value.length > 0)
                      // UpdateRetailers(e.target.value)
                    }}
                  >
                    {wholesalers?.map(({ name, id }) => (
                      <option key={name} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{formik.errors.wholesalerUid}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!formik.errors.retailerUid}>
                  <FormLabel>Retailer</FormLabel>
                  <Select
                    id="retailer"
                    icon={<MdArrowDropDown />}
                    value={formik.values.retailerUid}
                    onChange={formik.handleChange}
                  >
                    {retailers.map(({ name, id }) => (
                      <option key={name} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{formik.errors.retailerUid}</FormErrorMessage>
                </FormControl>
              </Stack>
            </Stack> */}
                  <Stack>
                    <Stack>
                      <Text as="h2" fontSize="3xl" fontWeight="semibold" pb="4">
                        DELIVERY
                      </Text>
                    </Stack>
                    <Stack spacing={4}>
                      <FormControl isRequired isInvalid={!!formik.errors.po_number}>
                        <FormLabel>Purchase Order Number</FormLabel>
                        <Input
                          id="po_number"
                          type="po_number"
                          value={formik.values.po_number || ''}
                          onChange={formik.handleChange}
                        />
                        <FormErrorMessage>{formik.errors.po_number}</FormErrorMessage>
                      </FormControl>
                      <FormControl isRequired>
                        <FormLabel>Wholesaler</FormLabel>
                        <Select
                          id="wholesaler"
                          placeholder="Select Wholesaler"
                          icon={<MdArrowDropDown />}
                          value={formik.values.wholesaler?.company?.id}
                          onChange={event => {
                            formik.handleChange(event)
                            fetchRetailers(event.target.value)
                          }}
                        >
                          {_wholesalers &&
                            _wholesalers.map(({ name, id }) => (
                              <option key={id} value={id}>
                                {name}
                              </option>
                            ))}
                        </Select>
                      </FormControl>
                      {order?.orderWholesalersCustomer?.company && (
                        <div>
                          <div>Wholesaler Address:</div>
                          <div>{order.orderWholesalersCustomer.company.address1}</div>
                          <div>{order.orderWholesalersCustomer.company.address2}</div>
                          <div>
                            {order.orderWholesalersCustomer.company.city},{' '}
                            {order.orderWholesalersCustomer.company.state}{' '}
                            {order.orderWholesalersCustomer.company.zip}
                          </div>
                        </div>
                      )}
                      <FormControl>
                        <FormLabel>Retailer</FormLabel>
                        <Select
                          id="retailer"
                          placeholder="Select Retailer"
                          icon={<MdArrowDropDown />}
                          value={formik.values.retailer?.company?.id}
                          onChange={formik.handleChange}
                        >
                          {_retailers.map(({ name, id }) => (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                      {order?.orderRetailersCustomer?.company && (
                        <div>
                          <div>Retailer Address:</div>
                          <div>{order.orderRetailersCustomer.company.address1}</div>
                          <div>{order.orderRetailersCustomer.company.address2}</div>
                          <div>
                            {order.orderRetailersCustomer.company.city},{' '}
                            {order.orderRetailersCustomer.company.state}{' '}
                            {order.orderRetailersCustomer.company.zip}
                          </div>
                        </div>
                      )}
                      <FormControl isInvalid={!!formik.errors.freightType}>
                        <FormLabel>Freight Type</FormLabel>
                        <Select
                          id="freightType"
                          placeholder="Select Freight Type"
                          icon={<MdArrowDropDown />}
                          value={formik.values.freightType || ''}
                          onChange={formik.handleChange}
                        >
                          <option key={FreightType.TRIAXLE} value={FreightType.TRIAXLE}>
                            {FreightType.TRIAXLE}
                          </option>
                          <option key={FreightType.RAILCAR} value={FreightType.RAILCAR}>
                            {FreightType.RAILCAR}
                          </option>
                        </Select>
                        <FormErrorMessage>{formik.errors.freightType}</FormErrorMessage>
                      </FormControl>
                      <FormControl isInvalid={!!formik.errors.instructions}>
                        <FormLabel>Instructions</FormLabel>
                        <Textarea
                          id="instructions"
                          value={formik.values.instructions || ''}
                          onChange={formik.handleChange}
                        />
                        <FormErrorMessage>{formik.errors.instructions}</FormErrorMessage>
                      </FormControl>
                    </Stack>
                    <Stack>
                      <Stack>
                        <Text as="h2" fontSize="3xl" fontWeight="semibold" pb="4">
                          Attachments
                        </Text>
                      </Stack>
                      <Stack>
                        {order &&
                          order.attachments &&
                          order.attachments.length > 0 &&
                          order.attachments.map((attachment, idx) => (
                            <React.Fragment key={idx}>
                              <AttachmentWidget
                                attachment={attachment}
                                onDelete={() => {
                                  order.attachments?.splice(idx, 1)
                                  const orderCopy = Object.assign({}, order)
                                  setOrder(orderCopy)
                                }}
                              />
                            </React.Fragment>
                          ))}
                        <p>Upload new Attachment</p>
                        <UploadAttachment
                          order={order}
                          onUpload={(attachment: Attachment) => {
                            order?.attachments?.push(attachment)
                            const orderCopy = Object.assign({}, order)
                            setOrder(orderCopy)
                          }}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack>
                  <Stack>
                    <Text as="h2" fontSize="3xl" fontWeight="semibold" pb="4">
                      <span className="pr-5">ORDER</span>{' '}
                      <Button type="button" onClick={onDownloadCSV}>
                        Download CSV
                      </Button>
                    </Text>
                  </Stack>
                  <Stack spacing={4}>
                    <Table
                      taxes={formik.values.taxes || '0'}
                      freight={formik.values.freight || '0'}
                      handleChange={e => {
                        formik.handleChange(e)
                        formik
                          .submitForm()
                          .then(() => {
                            console.log('submitted')
                          })
                          .catch(err => {
                            console.error(err)
                          })
                      }}
                      columns={columns as any}
                      data={data}
                      removeRow={(index, removedRow) => {
                        removeOrderLine(removedRow, index)
                      }}
                      updateRow={(index, updatedRow) => {
                        updateOrderLine(updatedRow)
                        setData(
                          data.map((r, i) => {
                            if (i === index) return updatedRow
                            else return r
                          }),
                        )
                      }}
                    />
                    <Box id="button-container">
                      <Button leftIcon={<AddIcon />} mt="4" id="trigger" ref={setTriggerRef}>
                        Add Line
                      </Button>
                      {mounted && (
                        <Box
                          bg={tooltipColor}
                          borderColor={'transparent'}
                          ref={setTooltipRef}
                          {...getTooltipProps({
                            className: 'tooltip-container',
                            style: visible
                              ? { visibility: 'visible', boxShadow: 'none' }
                              : { visibility: 'hidden', pointerEvents: 'none' },
                          })}
                        >
                          <AddLineForm
                            // Save newRow
                            addRow={newRow => {
                              updateOrderLines(newRow)
                            }}
                            // TODO: pull this data from backend
                            seriesData={inventoryData}
                            itemData={itemData}
                          />
                        </Box>
                      )}
                    </Box>
                  </Stack>
                  <Flex justify="end" mt="2">
                    <Button
                      type="button"
                      mt="4"
                      onClick={() => {
                        try {
                          setIsLoading(true)
                          sendOrder(formik.values) //This may change with the new multipage
                          //Go to lineItems.
                        } catch {
                          toastNotification('error', 'Error', 'Something went wrong.')
                        } finally {
                          setIsLoading(false)
                        }
                      }}
                    >
                      {isLoading ? (
                        <CircularProgress isIndeterminate size="24px" color="teal" />
                      ) : order ? (
                        'Update'
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </Flex>
                </Stack>
              </HStack>
            </form>
          )
        }}
      </Formik>
    </PrivateRouteLayout>
  )
}
