import { Api } from '.'
import { Company } from '../models/company'

export default class CompanyApi {
  static async get(uid: string) {
    return Api.get<Company>(`/company/${uid}`)
  }

  static async getWholesalers() {
    return Api.get<Company[]>(`/company/getwholesalers/`)
  }

  static async getRetailersByWholesaler(id: string) {
    return Api.get<Company[]>(`/company/getretailersbywholesaler/${id}`)
  }
}
