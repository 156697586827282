import { Api } from '.'
import { Item } from '../models/item'

export default class ItemApi {
  static async get(id: string) {
    return Api.get<Item>(`/item/${id}`)
  }

  static async create(order: Item) {
    return Api.post<Item>(`/item/`, JSON.stringify(order), {
      headers: { 'Content-Type': 'application/json' },
    })
  }

  static async update(order: Item) {
    return Api.patch<Item>(`/item/${order.id}`, JSON.stringify(order), {
      headers: { 'Content-Type': 'application/json' },
    })
  }

  static async list() {
    return Api.get<Item[]>('/item/')
  }

  static async remove(order: Item) {
    return Api.delete<Item>(`/item/${order.id}`)
  }
}
